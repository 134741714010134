import React from 'react';
import NotificationForm from './NotificationForm';
import NotificationsTable from './NotificationsTable';

const Notifications = () => {

    return <div>
        <NotificationForm/>
        <NotificationsTable />
    </div>
}

export default Notifications