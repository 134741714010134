import React, { useState } from "react"
import { NotificationFormContainer } from "./Notifications.style"
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { useAppDispatch } from "../../../redux/hooks";
import { CreateNotificationToAll, NotificationType } from "../../../models/notifications/notification";
import { Role } from "../../../models/user/role";
import { sendNotificationToAllUsersAPI } from "../../../services/api/notificationsApi";

// Sample list for notification routes
const notificationRoutes = [
    { value: '/home', label: 'Home' },
    { value: '/premise', label: 'Premise' },
    { value: '/event', label: 'Event' },
    { value: '/profile', label: 'Profile' },
];

// New RoleSelector component for handling role selection
const RoleSelector: React.FC<{ roles: string[], setRoles: React.Dispatch<React.SetStateAction<string[]>> }> = ({ roles, setRoles }) => {
    const handleRoleChange = (role: string) => {
        setRoles(prevRoles => 
            prevRoles.includes(role) 
                ? prevRoles.filter(r => r !== role) 
                : [...prevRoles, role]
        );
    };

    return (
        <>
            {Object.values(Role)
                .filter(role => typeof role === "string" && role !== "Admin")
                .map(role => (
                    <div className="mb-3" key={role}>
                        <label>
                            <input 
                                type="checkbox" 
                                name={role.toString()} 
                                checked={roles.includes(role.toString())} 
                                onChange={() => handleRoleChange(role.toString())} 
                            />
                            {role}
                        </label>
                    </div>
                ))}
        </>
    );
};

const NotificationForm = () => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [route, setRoute] = useState(notificationRoutes[0].value);
    const [roles, setRoles] = useState<string[]>([]); 

    const handlePreview = () => {
        // const notification = buildNotification()
       alert(`_________\n\n${title}\n${content}\n_________\nSending to: ${roles}`)
    };
    const handleSend = () => {
        if (!validateForm()) return;
        const notification = buildNotification();
        sendNotificationToAllUsersAPI(notification).then(response => {
            alert(response)
        }).catch(error => {
            const errorMessage = error?.response?.data?.message || error.message; // Extracting the message
            alert(errorMessage); // Displaying the error message
        })
    }

    const buildNotification = (): CreateNotificationToAll => {
        return {
            title,
            content,
            route,
            type: NotificationType.General,
            targetedRoles: roles.map(role => Role[role as keyof typeof Role])
        };
    }
    
const validateForm = () => {
    const errorMessages = [];

        if (!title.trim()) {
            errorMessages.push("Title cannot be empty.");
        }
        if (!content.trim()) {
            errorMessages.push("Content cannot be empty.");
        }
        if (roles.length === 0) {
            errorMessages.push("At least one role must be selected.");
        }

        if (errorMessages.length > 0) {
            alert(errorMessages.join("\n"));
            return false;
        }
        return true

}
    return (
        <NotificationFormContainer className="p-4">
            <h2 className="mb-4">Create Notification</h2> {/* Title above inputs */}
            <div className="mb-3">
                <input 
                    type="text" 
                    className="form-control" // Bootstrap styling
                    placeholder="Notification Title" 
                    value={title} 
                    onChange={(e) => setTitle(e.target.value)} 
                />
            </div>
            <div className="mb-3">
                <textarea 
                    className="form-control" // Bootstrap styling
                    placeholder="Notification Body" 
                    value={content} 
                    onChange={(e) => setContent(e.target.value)} 
                />
            </div>
            <RoleSelector roles={roles} setRoles={setRoles} />
            <div className="d-flex justify-content-between">
                <button className="btn btn-secondary" onClick={handlePreview}>Preview</button>
                <button className="btn btn-primary" onClick={handleSend}>Send</button>
            </div>
        </NotificationFormContainer>
    )
}

export default NotificationForm