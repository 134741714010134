import { Role } from "../user/role"

export type Notification = {
    id: string
    createdOn: Date
    title: string
    content: string
    url: string
    notificationType: NotificationType      
}

export type CreateNotificationToAll = {
    title: string
    content: string
    route: string
    type: NotificationType
    targetedRoles: Role[]
}

export enum NotificationType
{
    Reservation,
    Promotion,
    System,
    General
}