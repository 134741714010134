export const AUTH_BASE_ROUTE = `/auth`;

export const BASE_ROUTE = `/hostAdmin`;

export const DASHBOARD = `dashboard`;
export const MAP = `map`;
export const USER = `users`;
export const PREMISE = `premises`;
export const RESERVATION_ANALYTICS = 'reservation-analytics';
export const HowItWorks_Customer = '/howItWorks-customer';
export const HowItWorks_Manager = '/howItWorks-manager';
export const GIVEAWAY = 'giveaway'
export const NOTIFICATIONS = 'notifications'
