import React, { FC, useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAppDispatch } from '../../../redux/hooks';
import { Pagination } from '../../../utils/pagination/pagination';

import { formatDate } from '../../../utils/helpers';
import { Notification, NotificationType } from '../../../models/notifications/notification';
import { getAllNotificationsByTypeAPI } from '../../../services/api/notificationsApi';


const NotificationsTable: FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    itemsPerPage: 20,
    totalPages: 0,
    currentPage: 1,
  });
  const pageSize = 20;
  const dispatch = useAppDispatch();

  useEffect(() => {
    getAllNotificationsByTypeAPI(NotificationType.General,pagination).then(response => {
        setNotifications(response);
    })
  }, []);


  return (
    <div>
      <h2> General Notifications </h2>
    
      <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sent Date</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Body</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>URL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{formatDate(row.createdOn.toString())}</TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.content}</TableCell>
              <TableCell>{NotificationType[row.notificationType]}</TableCell>
              <TableCell>{row.url}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

export default NotificationsTable;