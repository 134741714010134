import { Map } from '../../models/mapElement/map';
import { PredefinedFlter } from '../../models/predefinedFilter/predefinedFilter';
import { ChangeMainTableMapModel, Premise } from '../../models/premise/premise';
import { PremiseTag } from '../../models/premiseTag/premiseTag';
import { PremiseType } from '../../models/premiseType/premiseType';
import { PaginationRequest } from '../../utils/pagination/pagination';
import { PREDEFINEDFILTER, PREMISE, TABLEMAP } from '../constants';
import axiosInstance from '../networkAxios';

export const getAllPremisesApi = (pagination: PaginationRequest) => {
  const withoutPaginationParam = pagination.withoutPagination !== undefined
    ? `&withoutPagination=${pagination.withoutPagination}`
    : '';

  return axiosInstance.get<Premise[]>(
    `${PREMISE}?PageIndex=${pagination.currentPage}&PageSize=${pagination.itemsPerPage}${withoutPaginationParam}`,
  );
};

export const getPremiseMapsApi = (premiseId: string) => {
  return axiosInstance.get<Map[]>(`${TABLEMAP}/premise/${premiseId}`);
};

export const postPremiseApi = (premise: Premise) => {
  const formData = new FormData();

  formData.append('name', premise.name);
  formData.append('pib', premise.pib);
  formData.append('managerId', premise.managerId);
  formData.append('premiseTypeId', premise.premiseTypeId);
  formData.append('description', premise.description);

  // Append phone numbers
  premise.phoneNumbers.forEach((phoneNumber, index) => {
    formData.append(`phoneNumbers[${index}]`, phoneNumber);
  });

  // Append location
  formData.append('location.street', premise.location.street);
  formData.append('location.number', premise.location.number);
  formData.append('location.city', premise.location.city);
  formData.append('location.lat', premise.location.lat.toString());
  formData.append('location.lng', premise.location.lng.toString());

  // Append images
  premise.images.forEach((image, index) => {
    formData.append(`images[${index}].path`, image.path || '');
    formData.append(`images[${index}].order`, image.order.toString());
    if (image.imageFile) {
      formData.append(`images[${index}].imageFile`, image.imageFile);
    }
  });

  // Append discount
  formData.append('discount.amount', premise.discount.amount.toString());
  formData.append('discount.description', premise.discount.description || '');

  // Append menuItems
  premise.menuItems.forEach((menuItem, index) => {
    formData.append(`menuItems[${index}].path`, menuItem.path || '');
    formData.append(`menuItems[${index}].order`, menuItem.order.toString());
    if (menuItem.imageFile) {
      formData.append(`menuItems[${index}].imageFile`, menuItem.imageFile);
    }
  });

  // Append time settings
  formData.append(
    'timeSettings.maxDurationInMinutes',
    premise.timeSettings.maxDurationInMinutes.toString(),
  );
  formData.append(
    'timeSettings.minDurationInMinutes',
    premise.timeSettings.minDurationInMinutes.toString(),
  );
  formData.append(
    'timeSettings.minStartTimeFromNowInMinutes',
    premise.timeSettings.minStartTimeFromNowInMinutes.toString(),
  );
  formData.append(
    'timeSettings.selectableMinuteInterval',
    premise.timeSettings.selectableMinuteInterval.toString(),
  );
  formData.append('timeSettings.defaultDurationInMinutes', '120');
  premise.timeSettings.workHours.forEach((workHour, index) => {
    formData.append(
      `timeSettings.workHours[${index}].dayOfWeek`,
      (index !== 7 ? workHour.dayOfWeek : 0).toString(),
    );
    formData.append(
      `timeSettings.workHours[${index}].openingTimeInMinutes`,
      workHour.openingTimeInMinutes.toString(),
    );
    formData.append(
      `timeSettings.workHours[${index}].closingTimeInMinutes`,
      workHour.closingTimeInMinutes.toString(),
    );
  });

  // Append tags
  premise.tags.forEach((tag, index) => {
    formData.append(`tags[${index}]`, tag);
  });

  // Append predefined filters
  premise.predefinedFilters.forEach((filter, index) => {
    formData.append(`predefinedFilters[${index}]`, filter);
  });

  // Append table types
  premise.tableTypes.forEach((type, index) => {
    formData.append(`tableTypes[${index}]`, type.name);
  });

  // Append other properties

  // Append any additional properties here...

  return axiosInstance.post(`${PREMISE}`, formData);
};

export const removePremiseApi = (id: string) => {
  return axiosInstance.delete(`${PREMISE}/${id}`);
};

export const putPremiseApi = (premise: Premise) => {
  const formData = new FormData();

  formData.append('id', premise.id);
  formData.append('name', premise.name);
  formData.append('pib', premise.pib);
  formData.append('managerId', premise.managerId);
  formData.append('premiseTypeId', premise.premiseTypeId);
  formData.append('description', premise.description);
  formData.append('mainTableMapId', premise.mainTableMapId);

  // Append phone numbers
  premise.phoneNumbers.forEach((phoneNumber, index) => {
    formData.append(`phoneNumbers[${index}]`, phoneNumber);
  });

  // Append location
  formData.append('location.street', premise.location.street);
  formData.append('location.number', premise.location.number);
  formData.append('location.city', premise.location.city);
  formData.append('location.lat', premise.location.lat.toString());
  formData.append('location.lng', premise.location.lng.toString());

  // Append discount
  formData.append('discount.amount', premise.discount.amount.toString());
  formData.append('discount.description', premise.discount.description || '');


  // Append images
  premise.images.forEach((image, index) => {
    formData.append(`images[${index}].path`, image.path || '');
    formData.append(`images[${index}].order`, image.order.toString());
    if (image.imageFile !== null && image.imageFile) {
      formData.append(`images[${index}].imageFile`, image.imageFile);
    }
  });

  // Append menuItems
  premise.menuItems.forEach((menuItem, index) => {
    formData.append(`menuItems[${index}].path`, menuItem.path || '');
    formData.append(`menuItems[${index}].order`, menuItem.order.toString());
    if (menuItem.imageFile) {
      formData.append(`menuItems[${index}].imageFile`, menuItem.imageFile);
    }
  });

  // #region Time settings
  formData.append(
    'timeSettings.maxDurationInMinutes',
    premise.timeSettings.maxDurationInMinutes.toString(),
  );
  formData.append(
    'timeSettings.minDurationInMinutes',
    premise.timeSettings.minDurationInMinutes.toString(),
  );
  formData.append(
    'timeSettings.minStartTimeFromNowInMinutes',
    premise.timeSettings.minStartTimeFromNowInMinutes.toString(),
  );
  formData.append(
    'timeSettings.selectableMinuteInterval',
    premise.timeSettings.selectableMinuteInterval.toString(),
  );
  formData.append(
    'timeSettings.defaultDurationInMinutes',
    premise?.timeSettings?.defaultDurationInMinutes.toString() || '',
  );
  premise.timeSettings.workHours.forEach((workHour, index) => {
    formData.append(
      `timeSettings.workHours[${index}].dayOfWeek`,
      (index !== 7 ? workHour.dayOfWeek : 0).toString(),
    );
    formData.append(
      `timeSettings.workHours[${index}].openingTimeInMinutes`,
      workHour.openingTimeInMinutes.toString(),
    );
    formData.append(
      `timeSettings.workHours[${index}].closingTimeInMinutes`,
      workHour.closingTimeInMinutes.toString(),
    );
  });
  // #endregion

  // Append tags
  premise.tags.forEach((tag, index) => {
    formData.append(`tags[${index}]`, tag);
  });

  // Append predefined filters
  premise.predefinedFilters.forEach((filter, index) => {
    formData.append(`predefinedFilters[${index}]`, filter);
  });

  // Append table types
  premise.tableTypes.forEach((type, index) => {
    formData.append(`tableTypes[${index}].name`, type.name);
    formData.append(`tableTypes[${index}].id`, type.id);
    formData.append(`tableTypes[${index}].premiseId`, premise.id);
  });

  return axiosInstance.put(`${PREMISE}`, formData);
};

// Premise types
export const getAllPremiseTypesApi = () => {
  return axiosInstance.get<PremiseType[]>(`${PREMISE}/types`);
};

// Tags
export const getAllTagsApi = () => {
  return axiosInstance.get<PremiseTag[]>(`${PREMISE}/tags`);
};

// Predefined filters
export const getAllPredefinedFiltersApi = () => {
  return axiosInstance.get<PredefinedFlter[]>(`${PREDEFINEDFILTER}`);
};

export const changeMainTableMapAPI = (data: ChangeMainTableMapModel) => {
  return axiosInstance.put<Premise>(`${PREMISE}/tablemap/main`, data);
};
