import styled from "styled-components";


export const NotificationPageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    justify-content: center;
    
`
export const NotificationFormContainer = styled.div`
    width: 80%;
    border: 1px solid black;
    border-radius: 10px;
    margin-bottom: 20px;
`
