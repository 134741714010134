import React, { FC, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../../../components/utils/table';
import { CustomButton } from '../../../../components/utils/table.styled';
import { Premise } from '../../../../models/premise/premise';
import { useAppDispatch } from '../../../../redux/hooks';
import {
  removeActiveMap,
  removeActiveRegion,
  removeActiveTable,
} from '../../../../redux/map/mapSlice';
import { removeActivePremise, setActivePremise } from '../../../../redux/premise/premiseSlice';
import { getAllPremises } from '../../../../redux/premise/premiseThunk';
import axiosInstance from '../../../../services/networkAxios';
import { PREMISE } from '../../../../services/routes';
import { TableData } from '../../../../types/tableData';
import colors from '../../../../utils/colors';
import { Pagination, parsePagination } from '../../../../utils/pagination/pagination';
import AddPremise from '../add/AddPremise';

const Premises: FC = () => {
  const [premises, setPremises] = useState<Premise[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [pagination, setPagination] = useState<Pagination>({
    itemsPerPage: 20,
    totalPages: 0,
    currentPage: 1,
  });
  const [requestedPage, setRequestedPage] = useState<number>(1);
  const pageSize = 20;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const toggleModal = () => {
    setModalShow(!modalShow);
    if (modalShow) {
      dispatch(removeActivePremise());
    }
  };

  const changePagination = (page: number) => {
    setRequestedPage(page);
  };

  useEffect(() => {
    dispatch(removeActiveMap());
    dispatch(removeActiveRegion());
    dispatch(removeActiveTable());
    dispatch(getAllPremises({ itemsPerPage: pageSize, currentPage: requestedPage }))
      .unwrap()
      .then((response: any) => {
        console.log(response.data[0].discount);
        
        setPremises(response.data);
        setPagination(parsePagination(response.headers['x-pagination']));
      })
      .catch(error => console.log(error));
  }, [requestedPage]);

  const tableData: TableData = {
    headers: { name: 'Name', description: 'Description', managerId: 'Manager Id' },
    data: premises,
    actions: [
      {
        name: 'Remove',
        function: (premise: Premise) => {
          axiosInstance.delete(`${PREMISE}/${premise.id}`);
        },
        buttonColor: colors.danger,
      },
      {
        name: 'Edit',
        function: (premise: Premise) => {
          dispatch(setActivePremise(premise));
          toggleModal();
          setEdit(true);
        },
        buttonColor: colors.warning,
      },
      {
        name: 'Open Map',
        function: (premise: Premise) => {
          dispatch(setActivePremise(premise));
          navigate('/hostAdmin/map');
        },
        buttonColor: colors.info,
      },
    ],
  };

  return (
    <Container>
      <h2> Premises </h2>
      <Row>
        <CustomButton
          color={colors.confirm}
          onClick={() => {
            dispatch(removeActivePremise());
            setModalShow(true);
            setEdit(false);
          }}
        >
          Add New
        </CustomButton>
      </Row>
      <hr />
      {premises && (
        <Row>
          <TableComponent
            data={tableData}
            pagination={pagination}
            changePagination={changePagination}
          />
        </Row>
      )}

      <AddPremise show={modalShow} onHide={toggleModal} edit={edit} />
    </Container>
  );
};

export default Premises;
