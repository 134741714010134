import { CreateNotificationToAll, Notification, NotificationType } from "../../models/notifications/notification";
import { PaginationRequest } from "../../utils/pagination/pagination";
import { NOTIFICATIONS_API } from "../constants";
import axiosInstance from "../networkAxios";

export const getUserNotificationsAPI = async (id: string, pagination: PaginationRequest): Promise<Notification[]> => {
    const response = await axiosInstance.get(`${NOTIFICATIONS_API}/user/${id}`, { params: pagination });
    return response.data;
}

export const getAllNotificationsByTypeAPI = async (type: NotificationType, pagination: PaginationRequest): Promise<Notification[]> => {
    const response = await axiosInstance.get(`${NOTIFICATIONS_API}/get-by-type?type=${type}`, { params: pagination });
    return response.data;
}

export const sendNotificationToAllUsersAPI = async (model: CreateNotificationToAll) => {
    const response = await axiosInstance.post(`${NOTIFICATIONS_API}/send-to-all`, model)
    return response
}